
.column {
	margin: 15px 15px 0;
	padding: 0;
}
.column:last-child {
	padding-bottom: 60px;
}
.column::after {
	content: '';
	clear: both;
	display: block;
}
.column div {
	position: relative;
	float: left;
	width: 300px;
	height: 200px;
	margin: 0 0 0 25px;
	padding: 0;
}
.column div:first-child {
	margin-left: 0;
}
.column div span {
	position: absolute;
	bottom: -20px;
	left: 0;
	z-index: -1;
	display: block;
	width: 300px;
	margin: 0;
	padding: 0;
	color: #444;
	font-size: 18px;
	text-decoration: none;
	text-align: center;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
	opacity: 0;
}
figure {
	width: 300px;
	height: 200px;
	margin: 0;
	padding: 0;
	background: #fff;
	overflow: hidden;
}
figure:hover+span {
	bottom: -36px;
	opacity: 1;
}



/* Zoom In #1 */
.hover01 figure img {
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.hover01 figure:hover img {
	-webkit-transform: scale(1.3);
	transform: scale(1.3);
}

@-webkit-keyframes flash {
	0% {
		opacity: .4;
	}
	100% {
		opacity: 1;
	}
}
@keyframes flash {
	0% {
		opacity: .4;
	}
	100% {
		opacity: 1;
	}
}

/* Shine */
.hover14 figure {
	position: relative;
}
.hover14 figure::before {
	position: absolute;
	top: 0;
	left: -75%;
	z-index: 2;
	display: block;
	content: '';
	width: 50%;
	height: 100%;
	background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
	background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
	-webkit-transform: skewX(-25deg);
	transform: skewX(-25deg);
}
.hover14 figure:hover::before {
	-webkit-animation: shine .75s;
	animation: shine .75s;
}
@-webkit-keyframes shine {
	100% {
		left: 125%;
	}
}
@keyframes shine {
	100% {
		left: 125%;
	}
}


@-webkit-keyframes circle {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 1;
	}
	100% {
		width: 200%;
		height: 200%;
		opacity: 0;
	}
}
@keyframes circle {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 1;
	}
	100% {
		width: 200%;
		height: 200%;
		opacity: 0;
	}
}





/* address bar style */

#contact{

	background-image: url(../contact/images/formContact.jpg);
	opacity: 0.9;
}
@-webkit-keyframes animate-preloader {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }
  
  @keyframes animate-preloader {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }
  
  /*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
  @media screen and (max-width: 768px) {
	[data-aos-delay] {
	  transition-delay: 0 !important;
	}
  }
  
  
  
  @media (min-width: 1200px) {
	#hero {
	  background-attachment: fixed;
	}
  }
  
  @media (max-width: 991px) {
	#hero {
	  text-align: center;
	}
	#hero .container, #hero .container-fluid {
	  padding-top: 68px;
	}
	#hero .animated {
	  -webkit-animation: none;
	  animation: none;
	}
	#hero .hero-img {
	  text-align: center;
	}
	#hero .hero-img img {
	  width: 50%;
	}
  }
  
  @media (max-width: 768px) {
	#hero h1 {
	  font-size: 26px;
	  line-height: 36px;
	}
	#hero h2 {
	  font-size: 18px;
	  line-height: 24px;
	}
	#hero .hero-img img {
	  width: 60%;
	}
  }
  
  @media (max-width: 575px) {
	#hero .hero-img img {
	  width: 80%;
	}
  }
  
  @-webkit-keyframes up-down {
	0% {
	  transform: translateY(10px);
	}
	100% {
	  transform: translateY(-10px);
	}
  }
  
  @keyframes up-down {
	0% {
	  transform: translateY(10px);
	}
	100% {
	  transform: translateY(-10px);
	}
  }
  
  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  section {
	padding: 100px 0;
	overflow: hidden;
  }
  
  .section-bg {
	background-color: #f9f8ff;
  }
  
  .section-title-contact {
	text-align: center;
	padding-bottom: 30px;
  }
  
  .section-title-contact h2 {
	font-size: 32px;
	font-weight: bold;
	text-transform: uppercase;
	margin-bottom: 20px;
	padding-bottom: 20px;
	position: relative;
  }
  
  .section-title-contact h2::after {
	content: '';
	position: absolute;
	display: block;
	width: 50px;
	height: 3px;
	background: #5846f9;
	bottom: 0;
	left: calc(50% - 25px);
  }
  
  .section-title-contact p {
	margin-bottom: 0;
  }
  
  
  @media (max-width: 1200px) {
	.faq .faq-list {
	  padding: 0;
	}
  }
  
  /*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
  .contact .info-box {
	color: #444444;
	text-align: center;
	box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
	padding: 20px 0 30px 0;
	background: #fff;
  }
  
  .contact .info-box i {
	font-size: 32px;
	color: #5846f9;
	border-radius: 50%;
	padding: 8px;
  }
  
  .contact .info-box h3 {
	font-size: 20px;
	color: #2c4964;
	font-weight: 700;
	margin: 10px 0;
  }
  
  .contact .info-box p {
	padding: 0;
	line-height: 24px;
	font-size: 14px;
	margin-bottom: 0;
  }
  
  .contact .php-email-form {
	box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
	padding: 30px;
	background: #fff;
  }
  
  .contact .php-email-form .validate {
	display: none;
	color: red;
	margin: 0 0 15px 0;
	font-weight: 400;
	font-size: 13px;
  }
  
  .contact .php-email-form .error-message {
	display: none;
	color: #fff;
	background: #ed3c0d;
	text-align: left;
	padding: 15px;
	font-weight: 600;
  }
  
  .contact .php-email-form .error-message br + br {
	margin-top: 25px;
  }
  
  .contact .php-email-form .sent-message {
	display: none;
	color: #fff;
	background: #18d26e;
	text-align: center;
	padding: 15px;
	font-weight: 600;
  }
  
  .contact .php-email-form .loading {
	display: none;
	background: #fff;
	text-align: center;
	padding: 15px;
  }
  
  .contact .php-email-form .loading:before {
	content: "";
	display: inline-block;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	margin: 0 10px -6px 0;
	border: 3px solid #18d26e;
	border-top-color: #eee;
	-webkit-animation: animate-loading 1s linear infinite;
	animation: animate-loading 1s linear infinite;
  }
  
  .contact .php-email-form input, .contact .php-email-form textarea {
	border-radius: 5px;
	box-shadow: none;
	font-size: 14px;
  }
  
  .contact .php-email-form input:focus, .contact .php-email-form textarea:focus {
	border-color: #5846f9;
  }
  
  .contact .php-email-form input {
	padding: 20px 15px;
  }
  
  .contact .php-email-form textarea {
	padding: 12px 15px;
  }
  
  .contact .php-email-form button[type="submit"] {
	background: #5846f9;
	border: 0;
	padding: 10px 24px;
	color: #fff;
	transition: 0.4s;
	border-radius: 5px;
  }
  
  .contact .php-email-form button[type="submit"]:hover {
	background: #7b27d8;
  }
  
  @-webkit-keyframes animate-loading {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }
  
  @keyframes animate-loading {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }
  

/* brandBar css */





/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
	[data-aos-delay] {
	  transition-delay: 0 !important;
	}
  }
  
  
  
  
  
  
  /* Clients Section
  --------------------------------*/
  #clients {
	padding: 60px 0;
  }
  
  #clients img {
	max-width: 80%;
	opacity: 0.5;
	transition: 0.3s;
	padding: 15px 0;
  }
  
  #clients img:hover {
	opacity: 1;
  }
  
  #clients .owl-nav, #clients .owl-dots {
	margin-top: 5px;
	text-align: center;
  }
  
  #clients .owl-dot {
	display: inline-block;
	margin: 0 5px;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: #ddd;
  }
  
  #clients .owl-dot.active {
	background-color: #1bb1dc;
  }
  