.carousel-inner img {
    width: 100%;
    height: 100%;
  }
.line{
  
  
  text-decoration: none !important;
}

.blur{       
  filter:brightness(80%);
  /* color: white; */
  /* filter: opacity(300%);
  background: */
 

} 

.carousel{

transition:(.3s ease-in-out left);
}

.button-color{
  background-color:white;

}
.button-font{
  color:#43cae9 ;
}
