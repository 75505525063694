body {
    font-family: "Open Sans", sans-serif;
    color: #444444;
  }
  
  a {
    color: #3498db;
  }
  
  a:hover {
    color: #5faee3;
    text-decoration: none;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: "Raleway", sans-serif;
  }
  
  /*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
  
  
  
  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  section {
    padding: 60px 0;
    overflow: hidden;
  }
  
  .section-bg {
    background-color: #f7fbfe;
  }
  
  /*--------------------------------------------------------------
  # Services
  --------------------------------------------------------------*/
  .services .icon-box {
    padding:20%;
    position: relative;
    overflow: hidden;
    background: #fff;
    width:75vw;
    height:auto;
    box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
    transition: all 0.3s ease-in-out;
    border-radius: 15px;
    z-index: 1;
  }
  
  .services .icon-box::before {
    content: '';
    position: absolute;
    background: #e1f0fa;
    right: -60px;
    top: -40px;
    width: 150px;
    height: 150px;
    border-radius: 50px;
    transition: all 0.3s;
    z-index: -1;
  }
  
  .services .icon-box:hover::before {
    background: #ABF2D3;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }
  
  .services .icon {
    margin: 0 auto 20px auto;
    padding-top: 10px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    background: #3498db;
    transition: all 0.3s ease-in-out;
  }
  
  .services .icon i {
    font-size: 36px;
    line-height: 1;
    color: #fff;
  }
  
  .services .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size:3.3ch;
  }
  
  .services .title a {
    color: grey;
  }
  
  .services .description {
    font-size: 2ch;
    line-height: 28px;
    margin-bottom: 0;
  }
  
  .services .icon-box:hover .title a, .services .icon-box:hover .description {
    color: grey;
  }
  
  .services .icon-box:hover .icon {
    background: #fff;
  }
  
  .services .icon-box:hover .icon i {
    color: #3498db;
  }
  .inner-container{
    margin: 0% 12.5% !important;
  }
  .description{
    color:darkslategray;
  }